import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy',
  standalone: false,
})
export class GroupByPipe implements PipeTransform {
  transform(object: any[], term: string): any {
    return object.reduce(
      (result, item) => ({
        ...result,
        [item[term]]: [...(result[item[term]] || []), item],
      }),
      {},
    );
  }
}
