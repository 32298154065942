import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';

/**
 * Image component allow us to intercept the request to add header 'Authorization'
 */
@Component({
  selector: 'gogis-secure-image',
  template:
    '<img [src]="dataUrl$ | async" [alt]="alt" [class]="class" (load)="onLoad()"/>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SecureImageComponent implements OnChanges {
  @Output() imageLoaded = new EventEmitter<HTMLElement>();

  @Input() alt?: string;

  @Input() class?: string;

  @Input() private src = '';

  src$ = new BehaviorSubject(this.src);

  /**
   * this stream will contain the actual url that our img tag will load
   * everytime the src changes, the previous call would be canceled and the
   * new resource would be loaded
   */
  dataUrl$ = this.src$.pipe(switchMap((url) => this.loadImage(url)));

  /** @ignore */
  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
  ) {}

  /** @ignore */
  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  onLoad(): void {
    this.imageLoaded.emit();
  }

  private loadImage(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((e) =>
        // create an object url of that blob that we can use in the src attribute
        this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e)),
      ),
    );
  }
}
