import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSpace',
  standalone: false,
})
export class NumberSpacePipe implements PipeTransform {
  transform(value: number | string): string {
    if (!value) {
      return '';
    }

    if (typeof value === 'number') {
      value = value.toString();
    }
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
