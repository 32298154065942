import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

import { selectTokenId } from '@core/auth';

@Injectable()
export class AuthorizationHttpInterceptor implements HttpInterceptor {
  /** @ignore */
  constructor(private store: Store) {}

  /** intercept any http call done by the httpClient */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let jwtToken: string;
    this.store
      .select(selectTokenId)
      .pipe(take(1))
      .subscribe((token) => {
        jwtToken = token;
      });

    const requestToHandle = jwtToken
      ? request.clone({
          headers: request.headers.set('Authorization', `Bearer ${jwtToken}`),
          responseType: request.responseType,
          withCredentials: true,
        })
      : request;

    return next.handle(requestToHandle);
  }
}
