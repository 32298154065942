import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { selectUserProfile } from '@core/auth';
import {
  actionAddIssue,
  actionAddIssueSuccess,
  actionDeleteIssue,
  actionDeleteIssueSuccess,
  actionEditIssue,
  actionEditIssueSuccess,
  actionGetIssues,
  actionGetIssuesSuccess,
  actionGetPersonalTodoTasks,
  actionSolveIssue,
  actionSolveIssueSuccess,
} from '@modules/issue/store/issue-list.actions';

import { actionGenericFailed } from './api.actions';
import { IssueService } from './services/issue.service';

/** tables effects to import in EffectsModule.forRoot() */
@Injectable()
export class IssueEffects {
  getIssues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionGetIssues),
      switchMap((action) =>
        this.issueService.getIssuesList(action.ordering, action.filter).pipe(
          map((resp) => actionGetIssuesSuccess({ payload: resp.results })),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getPersonalTodoIssues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionGetPersonalTodoTasks),
      concatLatestFrom(() => this.store.select(selectUserProfile)),
      switchMap(([action, userProfile]) =>
        this.issueService.getPersonalTodoIssuesList(userProfile.nosaukums).pipe(
          map((resp) => actionGetIssuesSuccess({ payload: resp.results })),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  solveIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionSolveIssue),
      switchMap((action) =>
        this.issueService.solveIssue(action.issueId, action.data).pipe(
          map((response) =>
            actionSolveIssueSuccess({
              update: { id: action.issueId, changes: response },
            }),
          ),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  editIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionEditIssue),
      switchMap((action) =>
        this.issueService.udapteIssue(action.issueId, action.data).pipe(
          map((response) =>
            actionEditIssueSuccess({
              update: { id: action.issueId, changes: response },
            }),
          ),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  addIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionAddIssue),
      switchMap((action) =>
        this.issueService.addIssue(action.data).pipe(
          map((response) =>
            actionAddIssueSuccess({
              entity: response,
              payload: { issue: response, objectID: '3', modelID: 0 },
            }),
          ),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  deleteIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionDeleteIssue),
      switchMap((action) =>
        this.issueService.deleteIssue(action.issueId).pipe(
          map(() => actionDeleteIssueSuccess({ id: action.issueId })),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );
  /** @ignore */
  constructor(
    private actions$: Actions,
    private store: Store,
    private issueService: IssueService,
  ) {}
}
