import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import { actionAuthLogin } from '@core/auth';

@Component({
  selector: 'gogis-login-modal',
  templateUrl: './login-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoginModalComponent {
  /** authentication form. */
  authForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
  ) {}

  /** Execute on form login submit. */
  loginClick(): void {
    this.store.dispatch(actionAuthLogin(this.authForm.value));
  }
}
