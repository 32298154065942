/* eslint-disable @ngrx/no-dispatch-in-effects */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs';

import * as mapActions from '@modules/map/store/map.actions';
import {
  actionAddAttachment,
  actionAddAttachmentSuccess,
  actionAddTemporaryAttachment,
} from '@modules/map-panel/object-panel/object-attachment/object-attachment.actions';
import { ObjectAttachmentService } from '@modules/map-panel/object-panel/object-attachment/object-attachment.service';
import * as objecPaneltActions from '@modules/map-panel/object-panel/object-panel.actions';
import * as mapPanelActions from '@modules/map-panel/store/map-panel.actions';
import * as profileActions from '@modules/profile/store/profile.actions';
import * as searchActions from '@modules/spatial-search/store/spatial-search.actions';

import { actionGenericFailed } from './api.actions';
import {
  selectAPIMapComposition,
  selectAPIModelById,
  selectAPIModelByModelName,
  selectSelectedModelAndObject,
  selectTempAttachments,
} from './api.selectors';
import * as objectActions from './object.actions';
import { ObjectService } from './services/object.service';
import { OptionsService } from './services/options.service';

@Injectable()
export class ObjectEffects {
  retrieveObject = createEffect(() =>
    this.actions$.pipe(
      ofType(
        mapPanelActions.actionOpenObjectFromTable,
        mapActions.actionOpenObjectFromMap,
        searchActions.actionOpenObjectFromSearch,
        mapPanelActions.actionOpenObjectEditing,
        profileActions.actionOpenObjectFromProfile,
      ),
      tap((action) => {
        this.router.navigate(['/map'], {
          queryParamsHandling: 'merge',
          queryParams: {
            table: action.modelId,
            object: action.objectId,
            report: null,
          },
        });
      }),
      concatLatestFrom((action) =>
        this.store.select(selectAPIModelById(action.modelId)),
      ),
      switchMap(([action, model]) =>
        !model?.fieldsets
          ? of(
              objectActions.actionObjectGetOptions({
                contentType: action.modelId,
                objectId: action.objectId,
              }),
            )
          : of(
              objectActions.actionObjectGetData({
                contentType: action.modelId,
                model,
                objectId: action.objectId,
              }),
            ),
      ),
    ),
  );

  retrieveOptions = createEffect(() =>
    this.actions$.pipe(
      ofType(objectActions.actionObjectGetOptions),
      switchMap((action) =>
        this.optionsService.getTableOptions(action.contentType).pipe(
          switchMap((payload) =>
            from([
              objectActions.actionObjectGetOptionsSuccess({
                contentType: action.contentType,
                model: payload,
                objectId: action.objectId,
              }),
              objectActions.actionObjectGetData({
                contentType: action.contentType,
                model: payload,
                objectId: action.objectId,
              }),
            ]),
          ),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  retrieveData = createEffect(() =>
    this.actions$.pipe(
      ofType(objectActions.actionObjectGetData),
      concatLatestFrom((action) => [
        this.store.select(selectAPIModelById(action.contentType)),
        this.store.select(
          selectAPIModelByModelName(action.appName, action.modelName),
        ),
      ]),
      switchMap(([action, modelById, modelByName]) =>
        this.objectService
          .getObjectData(
            action.contentType || modelByName.django_content_type,
            action.objectId,
          )
          .pipe(
            map((payload) =>
              objectActions.actionObjectGetSuccess({
                contentType: action.contentType,
                objectId: action.objectId,
                object: payload,
                model: modelById,
              }),
            ),
            catchError((error: unknown) =>
              of(
                actionGenericFailed({
                  error: error.toString(),
                  actionType: action.type,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteObject = createEffect(() =>
    this.actions$.pipe(
      ofType(objectActions.actionDeleteConfirmation),
      concatLatestFrom(() => this.store.select(selectAPIMapComposition)),
      tap(() => {
        this.router.navigate(['/map'], {
          queryParamsHandling: 'preserve',
        });
      }),
      mergeMap(([action]) =>
        this.objectService.deleteObject(action.modelId, action.objectId).pipe(
          map(() => {
            if (!action.isInline) {
              return objectActions.actionObjectDeleteSucces({
                contentType: action.modelId,
                objectId: action.objectId,
              });
            } else {
              return objectActions.actionObjectInlineDeleteSuccess({
                contentType: action.modelId,
                objectId: action.objectId,
              });
            }
          }),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  objectInlineDeleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(objectActions.actionObjectInlineDeleteSuccess),
      concatLatestFrom(() => this.store.select(selectSelectedModelAndObject)),
      map(([, modelAndObject]) =>
        objectActions.actionObjectGetData({
          contentType: modelAndObject.selectedModel.django_content_type,
          objectId: modelAndObject.selectedObject.id,
        }),
      ),
    ),
  );

  addChoicesObject = createEffect(() =>
    this.actions$.pipe(
      ofType(objecPaneltActions.actionPostNewChoiceObject),
      mergeMap((action) =>
        this.objectService.addObject(action.modelId, action.data).pipe(
          map((resp) =>
            objectActions.actionChoiceObjectAddSuccess({
              contentType: action.modelId,
              objectId: resp.id,
              object: resp,
            }),
          ),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  addObject = createEffect(() =>
    this.actions$.pipe(
      ofType(objecPaneltActions.actionPostNewObject),
      mergeMap((action) =>
        this.objectService.addObject(action.modelId, action.data).pipe(
          concatLatestFrom(() => this.store.select(selectAPIMapComposition)),
          map(([resp, mapComp]) => {
            if (!action.isInline) {
              //Do not redirect if it is an inline object
              this.router.navigate([mapComp ? `/map/${mapComp}` : '/map'], {
                queryParams: { object: resp.id },
                queryParamsHandling: 'merge',
              });
            } else {
              return objectActions.actionObjectInlineAddSuccess({
                contentType: action.modelId,
                objectId: resp.id,
                object: resp,
              });
            }
            return objectActions.actionObjectAddSuccess({
              contentType: action.modelId,
              objectId: resp.id,
              object: resp,
            });
          }),
          catchError((error: unknown) =>
            of(
              actionGenericFailed({
                error: error.toString(),
                actionType: action.type,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  objectAddSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(objectActions.actionObjectAddSuccess),
      concatLatestFrom(() => this.store.select(selectTempAttachments)),
      map(([_action, tempInline]) => {
        if (tempInline.length) {
          tempInline.forEach((attachment) => {
            this.store.dispatch(
              actionAddAttachment({
                formData: attachment,
                objectID: _action.objectId,
              }),
            );
          });
        }
        return objectActions.actionObjectGetData({
          contentType: _action.contentType,
          objectId: _action.objectId,
        });
      }),
    ),
  );
  objectInlineAddSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(objectActions.actionObjectInlineAddSuccess),
      concatLatestFrom(() => this.store.select(selectSelectedModelAndObject)),
      mergeMap(([, modelAndObject]) => {
        if (modelAndObject?.selectedObject?.id) {
          return of(
            objectActions.actionObjectGetData({
              contentType: modelAndObject.selectedModel.django_content_type,
              objectId: modelAndObject.selectedObject.id,
            }),
          );
        }
        return EMPTY;
      }),
    ),
  );

  updateObject = createEffect(() =>
    this.actions$.pipe(
      ofType(objecPaneltActions.actionUpdateObject),
      mergeMap((action) =>
        this.objectService
          .updateObject(action.modelId, action.objectId, action.data)
          .pipe(
            map((resp) => {
              if (!action.isInline) {
                return objectActions.actionObjectUpdateSuccess({
                  contentType: action.modelId,
                  objectId: action.objectId,
                  object: resp,
                });
              } else {
                return objectActions.actionObjectInlineAddSuccess({
                  contentType: action.modelId,
                  objectId: resp.id,
                  object: resp,
                });
              }
            }),
            catchError((error: unknown) =>
              of(
                actionGenericFailed({
                  error: error.toString(),
                  actionType: action.type,
                }),
              ),
            ),
          ),
      ),
    ),
  );
  addAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionAddAttachment),
      concatLatestFrom(() => this.store.select(selectSelectedModelAndObject)),
      switchMap(([action, modelAndObject]) => {
        const objectID = action.objectID || modelAndObject.selectedObject?.id;
        if (objectID) {
          action.formData.append(
            'target_type',
            modelAndObject.selectedModel.django_content_type.toString(),
          );
          action.formData.append('target_id', objectID);
          return this.attachmentService.addAttachment(action.formData).pipe(
            map((attachmentResponse) => {
              this.toastr.success('Attachment succesfully added');
              return actionAddAttachmentSuccess({
                payload: {
                  objectID,
                  modelID: modelAndObject.selectedModel.django_content_type,
                  attachment: attachmentResponse,
                },
              });
            }),
            catchError((error: unknown) =>
              of(
                actionGenericFailed({
                  error: error.toString(),
                  actionType: action.type,
                }),
              ),
            ),
          );
        } else {
          this.store.dispatch(
            actionAddTemporaryAttachment({
              tempAttachment: {
                attachment: action.tempAttachment,
                formData: action.formData,
              },
            }),
          );
          return EMPTY;
        }
      }),
    ),
  );

  /** @ignore */
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private objectService: ObjectService,
    private optionsService: OptionsService,
    private attachmentService: ObjectAttachmentService,
    private toastr: ToastrService,
  ) {}
}
