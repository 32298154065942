import { Directive, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[gogisConnectForm]',
  standalone: false,
})
export class ConnectFormDirective {
  constructor(private formGroupDirective: FormGroupDirective) {}
  @Input('gogisConnectForm')
  set data(val: any) {
    if (val) {
      this.formGroupDirective.form.patchValue(val);
      this.formGroupDirective.form.markAsPristine();
    }
  }
}
