import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, take, tap } from 'rxjs';

import { selectIsAuthenticated } from './store/session.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectIsAuthenticated).pipe(
      tap((isAuth) => {
        if (!isAuth) {
          this.router.navigateByUrl('/');
          return false;
        }

        return true;
      }),
      take(1),
    );
  }
}
