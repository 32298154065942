import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { Collection } from 'ol';
import { defaults as defaultInteractions, Interaction } from 'ol/interaction';
import View from 'ol/View';

import { MapSettings } from '@core/api/models';

import { SimplemapService } from './simplemap.service';

/**
 * Simple Map Component: load and display a map
 *
 * @example
 * <gogis-simple-map id='map' class="flex w-64 h-64"></gogis-simple-map>
 */
@Component({
  selector: 'gogis-simple-map',
  template: ``,
  providers: [SimplemapService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SimplemapComponent implements OnInit {
  /** map id */
  @Input() id = 'map';

  @Input() config: any;

  /** a collection of controls */
  @Input() interactions: Collection<Interaction>;

  constructor(private mapService: SimplemapService) {}

  ngOnInit(): void {
    if (!this.config) {
      // create default config if not recieved from backend
      this.config = {
        zoom: 10,
        center: [585046.2919929801, 377214.08384146],
        projection: 'EPSG:3059',
      };
    }

    if (!this.interactions) {
      this.interactions = defaultInteractions();
    }
    this.mapService.setProjectionDefs(
      this.config.projection,
      '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    );

    const view = this.createView(this.config);
    this.mapService.createMap(this.id, view, this.interactions);

    setTimeout(() => {
      this.mapService.map.updateSize();
    }, 1);
  }

  /**
   * create view
   */
  createView(mapConfig: MapSettings): View {
    return new View({
      center: mapConfig.center,
      zoom: mapConfig.zoom,
      projection: mapConfig.projection,
      minZoom: 6,
      maxZoom: 22,
    });
  }
}
