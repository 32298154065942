import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, map, of, switchMap, tap } from 'rxjs';

import { selectIsAuthenticated } from '@core/auth';
import { ProfileService } from '@modules/profile/profile.service';

import {
  actionChangeLanguage,
  actionChangeLanguageFailure,
  actionChangeLanguageSuccess,
} from './header.actions';

@Injectable()
export class HeaderEffects {
  changeLanguage$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(actionChangeLanguage),
      tap((action) => {
        this.translate.use(action.payload);
      }),
      concatLatestFrom(() => this.store.select(selectIsAuthenticated)),
      switchMap(([action, isAuthenticated]) =>
        isAuthenticated
          ? this.profileService.saveUserLang(action.payload).pipe(
              map((response) => actionChangeLanguageSuccess({ response })),
              catchError((error: unknown) =>
                of(actionChangeLanguageFailure({ error: error.toString() })),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private translate: TranslateService,
    private profileService: ProfileService,
  ) {}
}
