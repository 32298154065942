import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { attachmentURL, dataURL } from '@core/http/endpoints';
import { LoadingState } from '@core/meta-reducers/call-state';
import { ObjectAttachmentService } from '@modules/map-panel/object-panel/object-attachment/object-attachment.service';

import {
  AttachmentResponse,
  ModelOptionsResponse,
  ObjectResponse,
  TableResponse,
} from '../models';

/**
 * Service to get object.
 */
@Injectable({
  providedIn: 'root',
})
export class ObjectService {
  /** @ignore */
  constructor(
    private http: HttpClient,
    private attachmentService: ObjectAttachmentService,
  ) {}

  getModelOptions(djangoContentType: number): Observable<ModelOptionsResponse> {
    return this.http.options<ModelOptionsResponse>(
      `${dataURL}/${djangoContentType}`,
    );
  }

  getAttachmentOptions(): Observable<ModelOptionsResponse> {
    return this.http.options<ModelOptionsResponse>(attachmentURL);
  }

  getObjectData(
    djangoContentType: number,
    objectId: string,
  ): Observable<ObjectResponse> {
    return this.http
      .get<ObjectResponse>(`${dataURL}/${djangoContentType}/${objectId}`)
      .pipe(
        map((response) => ({ ...response, callState: LoadingState.LOADED })),
      );
  }

  getAttachmentArray(
    attachments: TableResponse<AttachmentResponse>,
  ): AttachmentResponse[] {
    const attachmentArray: AttachmentResponse[] = [];
    attachments?.results
      .filter((a) => !!a)
      .forEach((a) => {
        a.file_type = this.attachmentService.getFileNameExtension(a.file);
        attachmentArray.push(a);
      });
    return attachmentArray;
  }

  deleteObject(djangoContentType: number, objectId: string): Observable<null> {
    return this.http.delete<null>(
      `${dataURL}/${djangoContentType}/${objectId}`,
    );
  }

  updateObject(
    djangoContentType: number,
    objectId: string,
    data: FormData,
  ): Observable<ObjectResponse> {
    return this.http
      .patch<ObjectResponse>(
        `${dataURL}/${djangoContentType}/${objectId}`,
        data,
      )
      .pipe(
        map((response) => ({ ...response, callState: LoadingState.LOADED })),
      );
  }

  addObject(
    djangoContentType: number,
    data: FormData,
  ): Observable<ObjectResponse> {
    return this.http
      .post<ObjectResponse>(`${dataURL}/${djangoContentType}`, data)
      .pipe(
        map((response) => ({ ...response, callState: LoadingState.LOADED })),
      );
  }
}
