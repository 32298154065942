import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DropdownComponent } from './dropdown.component';

/**
 * dropdown container component:
 * add a container to the popover
 *
 * @example
 * <gogis-dropdown>
 *   <gogis-dropdown-container></gogis-dropdown-container>
 * </gogis-dropdown>
 */
@Component({
  selector: 'gogis-dropdown-container',
  template: `
    <div
      class="z-50 md:absolute md:shadow-xl"
      role="group"
      [class.hidden]="!isOpen"
      [class.right-0]="dropright"
      [attr.aria-expanded]="isOpen"
    >
      <ng-content />
    </div>
  `,
  standalone: false,
})
export class DropdownContainerComponent {
  /**
   * boolean. Indicates if popover should open on the right side.
   * Default is false.
   */
  @Input() dropright = false;

  /**
   * Returns whether or not the popover is currently being open.
   */
  isOpen: boolean;

  /** @ignore */
  constructor(dropdown: DropdownComponent) {
    dropdown.isOpenChange$
      .pipe(takeUntilDestroyed())
      .subscribe((value: boolean) => (this.isOpen = value));
  }
}
