import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { svgIcons } from '@app/svg/svg';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { filter, take } from 'rxjs';

import { FooterEffects } from '@modules/map/footer/store/footer.effects';
import { MapPanelEffects } from '@modules/map-panel/store/map-panel.effects';
import { NotFoundComponent } from '@modules/not-found/not-found.component';
import { ProfileEffects } from '@modules/profile';
import { SidebarEffects } from '@modules/sidebar/store/sidebar.effects';
import { SharedModule } from '@shared/shared.module';

import { extModules } from '../build-specifics';
import { ApiEffects } from './api/api.effects';
import { IssueEffects } from './api/issue.effects';
import { ObjectEffects } from './api/object.effects';
import { TableEffects } from './api/table.effects';
import {
  actionCheckInitialLogin,
  AuthEffects,
  selectIsAuthenticated,
} from './auth';
import { AgreementModalComponent } from './auth/agreement-modal/agreement-modal.component';
import { LoginModalComponent } from './auth/login-modal/login-modal.component';
import { metaReducers, reducers } from './core.state';
import { HeaderComponent } from './header/header.component';
import { HeaderEffects } from './header/store/header.effects';
import { AuthorizationHttpInterceptor } from './http/authorization.interceptor';
import { HttpErrorInterceptor } from './http/errors.interceptor';
import { LanguageHttpInterceptor } from './http/language.interceptor';
import { LoaderComponent } from './http/loader/loader.component';
import { LoaderInterceptor } from './http/loader/loader.interceptor';
import { LoaderService } from './http/loader/loader.service';

/** AoT requires an exported function for factories */
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

/** message to show when translation is missing */
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(): string {
    return '%MISSING TRANSLATION%';
  }
}

/**
 * Check if user is login during app initialization,
 * initialization does not complete until the Promise is resolved.
 */
export function checkIfUserLogin(store: Store): () => Promise<unknown> {
  return () =>
    new Promise((resolve) => {
      store.dispatch(actionCheckInitialLogin());
      store
        .select(selectIsAuthenticated)
        .pipe(
          filter((isAuth) => isAuth !== null && isAuth !== undefined),
          take(1),
        )
        .subscribe(() => {
          resolve(true);
        });
    });
}

/**
 * the CoreModule should be importing most of things needed from start like
 * singleton services, universal components and other features
 * to keep our AppModule almost empty
 *
 * TODO: To prevent re-importing the core module elsewhere, add a guard for it in the `CoreModule` constructor.
 */
@NgModule({
  declarations: [
    HeaderComponent,
    LoaderComponent,
    NotFoundComponent,
    AgreementModalComponent,
    LoginModalComponent,
  ],
  exports: [HeaderComponent, LoaderComponent],
  imports: [
    // angular
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    // dependencies
    ToastNoAnimationModule.forRoot({
      progressBar: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      ApiEffects,
      IssueEffects,
      TableEffects,
      ObjectEffects,
      AuthEffects,
      ProfileEffects,
      MapPanelEffects,
      SidebarEffects,
      HeaderEffects,
      FooterEffects,
    ]),
    extModules,
    SvgIconsModule.forRoot({
      sizes: {
        xs: '16px',
        sm: '24px',
        md: '32px',
        lg: '40px',
        xl: '48px',
        xxl: '56px',
      },
      defaultSize: 'md',
      icons: svgIcons,
    }),
    // MatomoModule.forRoot({
    //   scriptUrl: '//matomo.sungis.lv/matomo.js',
    //   trackers: [
    //     {
    //       trackerUrl: 'https://matomo.sungis.lv/matomo.php',
    //       siteId: environment.production ? 3 : 2,
    //     },
    //   ],
    //   routeTracking: {
    //     enable: true,
    //   },
    // }),
    // local
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Store],
      useFactory: checkIfUserLogin,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    LoaderService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {}
