import {
  ChangeDetectionStrategy,
  Component,
  Host,
  OnInit,
} from '@angular/core';

import { Map } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';

import { SimplemapService } from '../simplemap.service';

/**
 * Layer Component: show a default layer
 *
 * @example
 * <gogis-simple-map id='map' class="flex w-64 h-64">
 * <gogis-simple-map-layer></gogis-simple-map-layer>
 * </gogis-simple-map>
 */
@Component({
  selector: 'gogis-simple-map-layer',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LayerComponent implements OnInit {
  private map: Map;

  constructor(
    @Host()
    private simplemapService: SimplemapService,
  ) {}

  ngOnInit(): void {
    // TODO either add more layer choice in this component or in the service
    this.map = this.simplemapService.map;
    this.map.addLayer(this.createMainTileLayer());
  }
  /** creates the hardcoded base tile layer the map will have */
  createMainTileLayer(): TileLayer<OSM> {
    return new TileLayer({
      opacity: 1,
      // Changed name to className. Options dosnt have parameter name
      className: 'Open Street Map Simple - WMTS',
      source: new OSM({}),
    });
  }
}
