import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, exhaustMap, of, tap } from 'rxjs';

import { MapPanelHistoryService } from '../map-panel-history.service';
import { actionObjectNavigateBack } from '../object-panel/object-panel.actions';
import { actionTableClose, actionTableGet } from './map-panel.actions';

@Injectable()
export class MapPanelEffects {
  backToTable = createEffect(() =>
    this.actions$.pipe(
      ofType(actionObjectNavigateBack),
      exhaustMap((action) => {
        const h = this.historyService.history;
        if (h.length > 1 && !action.toTable) {
          this.location.back();
          return EMPTY;
        } else {
          return of(
            actionTableGet({
              contentType: parseInt(h[h.length - 1].table, 10),
            }),
          );
        }
      }),
    ),
  );

  closePanel = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionTableClose),
        tap(() => {
          this.router.navigate(['/map'], {
            queryParams: { object: null, table: null, report: null },
            queryParamsHandling: 'merge',
          });
        }),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private location: Location,
    private historyService: MapPanelHistoryService,
  ) {}
}
