import {
  DestroyRef,
  Directive,
  EventEmitter,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BehaviorSubject, Subject, take } from 'rxjs';

/** *
 * Directive can be used to manage state of gogis-accordion-panel.
 *
 * @example
 * <gogis-accordion>
 *  <gogis-accordion-toggle></gogis-accordion-toggle>
 *  <gogis-accordion-panel></gogis-accordion-panel>
 *  <gogis-accordion-panel></gogis-accordion-panel>
 * </gogis-accordion>
 */
@Directive({
  selector: 'gogis-accordion, [gogisAccordion]',
  standalone: false,
})
export class AccordionDirective implements OnInit {
  @Input() total: number;

  accordionExpanded$ = new BehaviorSubject<number>(0);

  expandState$ = new BehaviorSubject<boolean>(false);

  /** directive have been clicked. */
  toggleClick = new EventEmitter<boolean>();

  /** Stream that emits true/false when openAll/closeAll is triggered. */
  readonly openCloseAllActions$ = new Subject<boolean>();

  /** subject to unsubscribe on destroy. */
  private destroyRef = inject(DestroyRef);

  constructor() {
    this.accordionExpanded$.pipe(takeUntilDestroyed()).subscribe((expanded) => {
      // eslint-disable-next-line rxjs-x/no-nested-subscribe
      this.expandState$.pipe(take(1)).subscribe((expandState) => {
        if ((expanded > 0 && !expandState) || (expanded === 0 && expandState)) {
          this.expandState$.next(!expandState);
        }
      });
    });
  }

  /** @ignore */
  ngOnInit(): void {
    this.setExpandedMin();
    this.toggleClick
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: boolean) => this.toggleAll(state));
  }

  /** Opens all accordion panel. */
  openAll(): void {
    this.setExpandedMax();
    this.openCloseAll(true);
  }

  /** Closes all accordion panel. */
  closeAll(): void {
    this.setExpandedMin();
    this.openCloseAll(false);
  }

  addExpanded(): void {
    const count = this.accordionExpanded$.value;
    if (this.total > count) {
      this.accordionExpanded$.next(count + 1);
    }
  }

  removeExpanded(): void {
    const count = this.accordionExpanded$.value;
    if (count > 0) {
      this.accordionExpanded$.next(count - 1);
    }
  }

  setExpandedMax(): void {
    this.accordionExpanded$.next(this.total);
  }

  setExpandedMin(): void {
    this.accordionExpanded$.next(0);
  }

  /** Toogle all the accordion panel */
  private toggleAll(state: boolean): void {
    if (state) {
      this.openAll();
    } else {
      this.closeAll();
    }
  }

  /** @ignore */
  private openCloseAll(expanded: boolean): void {
    this.openCloseAllActions$.next(expanded);
  }
}
