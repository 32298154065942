import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { GogisChoice } from '@core/api/models';

@Pipe({
  name: 'selectOptionsTranslate',
  standalone: false,
})
export class SelectOptionsTranslatePipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  transform(items: GogisChoice[]): GogisChoice[] {
    for (const item of items) {
      item.display_name = this.translateService.instant(item.display_name);
    }
    return items;
  }
}
