import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter applications to show only the ones who's verbose_name match input text
 */
@Pipe({
  name: 'userSearchFilter',
  standalone: false,
})
export class UserSearchFilterPipe implements PipeTransform {
  transform(usersList: any[], searchText: string): any[] {
    if (!usersList) {
      return [];
    }
    if (!searchText) {
      return usersList;
    }
    searchText = searchText.toLowerCase();

    const userResult = usersList.filter((user) =>
      user.nosaukums.toLowerCase().includes(searchText),
    );

    return Object.values(userResult);
  }
}
