import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, concatMap, EMPTY, map, of, switchMap } from 'rxjs';

import { selectIsAuthenticated } from '@core/auth';
import { ProfileService } from '@modules/profile/profile.service';

import {
  actionChangeProjection,
  actionChangeProjectionFailure,
  actionChangeProjectionSuccess,
} from './footer.actions';

@Injectable()
export class FooterEffects {
  saveUserProjectionPreference$ = createEffect(() =>
    this.action$.pipe(
      ofType(actionChangeProjection),
      concatMap((action) =>
        of(action).pipe(
          concatLatestFrom(() => this.store.select(selectIsAuthenticated)),
        ),
      ),
      switchMap(([action, isAuthenticated]) =>
        isAuthenticated
          ? this.profileService
              .saveUserProjection(action.projection.replace(/([^0-9])+/g, ''))
              .pipe(
                map((response) => actionChangeProjectionSuccess({ response })),
                catchError((error: unknown) =>
                  of(
                    actionChangeProjectionFailure({ error: error.toString() }),
                  ),
                ),
              )
          : EMPTY,
      ),
    ),
  );

  constructor(
    private action$: Actions,
    private profileService: ProfileService,
    private store: Store,
  ) {}
}
